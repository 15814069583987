
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import useTranslation from 'next-translate/useTranslation';
import { useContext, useState } from 'react';
import CollateralAssetList from '../../components/CollateralAssetList';
import CollateralBlock from '../../components/CollateralBlock';
import CompactHealthFactorBlock from '../../components/CompactHealthFactorBlock';
import { MetronomeContext } from '../../components/MetronomeContext';
import Page from '../../components/Page';
import RewardsBlock from '../../components/RewardsBlock';
import SyntheticAssetList from '../../components/SyntheticAssetList';
import SyntheticsBlock from '../../components/SyntheticsBlock';
import TabSelector from '../../components/TabSelector';
import getServerSideProps from '../../utils/serverProps';
export default function Index({ hasError }) {
    const { t } = useTranslation();
    const { selectedPoolData: { rewards = [] } = {} } = useContext(MetronomeContext);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const handleTabChange = index => setSelectedTabIndex(index);
    const rewardsEnabled = rewards.length > 0;
    return (<Page hasError={hasError} title={t('navigation.synth')}>
      <CompactHealthFactorBlock />
      <div className="mb-16 flex flex-col gap-4 md:flex-row md:gap-6">
        <CollateralBlock className={`md:order-1 md:m-0 ${rewardsEnabled ? 'md:w-4/12' : 'md:w-1/2'}`}/>
        <SyntheticsBlock className={`md:order-3 ${rewardsEnabled ? 'md:w-4/12' : 'md:w-1/2'}`}/>
        {rewardsEnabled && (<RewardsBlock className="md:order-4 md:m-0 md:w-4/12"/>)}
      </div>
      <div className="space-y-8 md:hidden">
        <TabSelector className="-mb-2" onClick={handleTabChange} selectedTabIndex={selectedTabIndex} setSelectedTabIndex={setSelectedTabIndex} tabs={[
            {
                component: CollateralAssetList,
                label: t('collaterals'),
                name: 'Collaterals'
            },
            {
                component: SyntheticAssetList,
                label: t('synthetics'),
                name: 'Synthetics'
            }
        ]}/>
      </div>
      <div className="hidden flex-col gap-9 sm:gap-6 md:flex md:flex-row md:gap-9">
        <div className="md:w-1/2">
          <CollateralAssetList />
        </div>
        <div className="md:w-1/2">
          <SyntheticAssetList />
        </div>
      </div>
    </Page>);
}
export {};

    async function __Next_Translate__getServerSideProps__191bf1373af__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/[shortName]/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__191bf1373af__ as getServerSideProps }
  